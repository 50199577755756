import React from 'react'
import './TopicCard.sass'
import MainButton from '../../../MainButton/MainButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

export default function TopicCard(props) {
    var topic = props.data
    var created_at = moment(topic.created_at).fromNow()

    var title = ""
    if (topic.title.length <= 50) {
        title = topic.title
    } else title = topic.title.slice(0, 50) + "..."
    
    var content = ""
    if (topic.content.length <= 115) {
        content = topic.content
    } else content = topic.content.slice(0, 115) + "..."

    var angleRight = <FontAwesomeIcon icon={faAngleRight} />
    
    return (
        <div className="topic-card">
            <div className="topic-card-body">
                <p className="meta">{topic.author}, {created_at}</p>
                <p className="topic-card-title">{title}</p>
                <p className="topic-card-content">{content}</p>
            </div>
            <MainButton href={"/article/" + topic.id }text="Read More " icon={angleRight} size="small" />
        </div>
    )
}