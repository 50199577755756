import React, { Component } from 'react'
import "./Testimony.sass"
import TestimonyCarousel from "./TestimoniCarousel/TestimoniCarousel"

export default class Testimony extends Component {
    render() {
        return (
            <div className="testimony">
                <p className="title">What They Said</p>
                <TestimonyCarousel  />
            </div>
        )
    }
}
