import React, { Component } from 'react'
import './TopicSection.sass'
import MoreArticle from '../../MoreArticle/MoreArticle';

export default class TopicSection extends Component {
    state = {
        title: "Topic Now Days",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor",
    }

    render() {
        return (
            <div className="topics">
                <p className="title">{this.state.title}</p>
                <p className="subtitle">{this.state.subtitle}</p>
                <div className="topic-cards">
                    <MoreArticle />
                </div>
            </div>
        )
    }
}
