import React, { Component } from 'react'
import './PlanCard.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import MainButton from '../../../MainButton/MainButton';


export default class PlanCard extends Component {
    state = {
        benefits: []
    }

    getBenefits = this.getBenefits.bind(this)

    render() {
        var plan = this.props.data
        var benefits = this.state.benefits.map(benefit => {
            return (
                <p className="benefit"><FontAwesomeIcon className="dot" icon={faCircle} />{benefit.name}</p>
            )
        })
        return (
            <div className="plan-card">
                <div className="card-body">
                    <p className="name">{plan.name}</p>
                    <div className="benefits">
                        {benefits}
                    </div>
                </div>
                <div className="card-footer">
                    <p className="price">{plan.price} IDR</p>
                    <MainButton href="/contact" text="Contact Us" size="small"/>
                </div>
            </div>
        )
    }
    
    getBenefits(productId, planId) {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "products/" + productId + "/plans/" + planId + "/benefits",
            method: "get"
        }).then(response => {
            this.setState({
                benefits: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        this.getBenefits(this.props.productId, this.props.data.id)
    }
}
