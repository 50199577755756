import React from 'react'
import "./MainButton.sass"

export default function MainButton(props) {
    var text = props.text
    var icon = props.icon
    var size = props.size
    var placement = props.placement
    var href = props.href
    var marginRight = props.mr ? ("mr-" + props.mr) : ("")

    if (size === "small") {
        return (
            <a href={href} className="small main-button">{ text } { icon }</a>
        )
    } else if (placement === "navbar") {
        return(
            <a href={href} className="placement-navbar main-button">{ text } { icon }</a>
        )
    } else {
        return (
            <a href={href} className={"main-button " + marginRight}>{ text } { icon }</a>
        )
    }
}
