import React, { Component } from 'react'
import Axios from 'axios';
import './TeamSection.sass'

export default class TeamSection extends Component {
    state= {
        teams: [],
    }

    getTeamMember = this.getTeamMember.bind(this)

    render() {
        var teams = this.state.teams.map(member => {
            return (
                <div className="member-card" key={member.id}>
                    <img src={member.image} alt="img-member"/>
                    <div className="info">
                        <p className="name">{member.name}</p>
                        <p className="position">{member.position}</p>
                        <p className="desc">{member.description}</p>
                    </div>
                </div>
            )
        })
        return (
            <div className="teams">
                <p className="section-title">Meet Our Team</p>
                <div className="member-card-wrapper">
                    {teams}
                </div>
            </div>
        )
    }

    getTeamMember() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "meet-our-team",
            method: "get"
        }).then(response => {
            this.setState({
                teams: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        this.getTeamMember()
    }
}
