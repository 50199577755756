import React, {useState, useEffect} from 'react'
import './BaseNavbar.sass';
import { Button, Nav, Navbar} from 'react-bootstrap'
import Logo from '../../KotaIcon.png'
import LoginModal from './LoginModal/LoginModal'
import MainModal from './MainModal/MainModal'
import MainButton from '../MainButton/MainButton'

export default function BaseNavbar(props) {
    const [loginModalShow, setLoginModalShow] = useState(false)
    const [productModalShow, setProductModalShow] = useState(false)
    const [serviceModalShow, setServiceModalShow] = useState(false)
    const [customClass, setClass] = useState("transparent-navbar")

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY !== 0){
                setClass("green-navbar")
            } else {
                setClass("transparent-navbar")
            }
        });
    });

    return (
            <Navbar id="navbar" fixed="top" expand="lg" variant="dark" className={"d-flex align-items-center " + customClass}>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
                    <Navbar.Collapse id="basic-navbar-nav" className="navbar-links">
                        <Nav className="center">
                            <Button variant="custom" onClick={() => setProductModalShow(true)}>Product</Button>
                            <Button variant="custom" onClick={() => setServiceModalShow(true)}>Services</Button>
                            <Nav.Link href="/about">About Us</Nav.Link>
                        </Nav>
                        <Nav className="right">
                            <Nav.Link href="/contact">Contact Us</Nav.Link>
                            <MainButton href="/try"text="Get Free Trial" placement="navbar"/>
                            <Button variant="custom" onClick={() => setLoginModalShow(true)}>Login</Button>
                        </Nav>
                </Navbar.Collapse>
                <LoginModal
                    show={loginModalShow}
                    onHide={() => setLoginModalShow(false)}
                />                    
                <MainModal
                    show={productModalShow}
                    onHide={() => setProductModalShow(false)}
                    modalType="product"
                />                    
                <MainModal
                    show={serviceModalShow}
                    onHide={() => setServiceModalShow(false)}
                    modalType="service"
                />
            </Navbar>
    )
}