import React, { Component } from 'react';
import './ProductsFlyer.sass'
import Laptop from "../../../img-component/laptop2.png"
import MainButton from "../../MainButton/MainButton"

export default class ProductsFlyer extends Component {
    state = {
        productName: "Products",
        img: Laptop,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor.",
        brief_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. "
    }

    render() {
        return (
            <div className="product">
                <p className="title">{this.state.productName}</p>
                <p className="subtitle">{this.state.description}</p>
                <img className="product-image" src={this.state.img} alt="laptop"/>
                <p className="product-brief">{this.state.brief_desc}</p>
                <div className="buttons">
                    <MainButton mr={3} href="/register" text="Try it for FREE" />
                    <MainButton href="/try" text="Explore Product" />
                </div>
            </div>
        )
    }
}
