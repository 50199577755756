import React, { Component } from 'react'
import './CustomerFlyer.sass'
import {} from "react"

export default class CustomerFlyer extends Component {
    state = {
        productName: "Customer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor.",
        customers: [
            {
                id: 1,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 2,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 3,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 4,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 5,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 6,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 7,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 8,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 9,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 10,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 11,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 12,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 13,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 14,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
            {
                id: 15,
                customerLogo: "https://insco.co.id/wp-content/uploads/2013/10/insco-logo-teks.png"
            },
        ]
    }

    render() {
        var customerLogo = this.state.customers.map(customer => {
            return (
                <img src={customer.customerLogo} alt="customer-logo" key={customer.id}/>
            )
        })

        return (
            <div className="customers">
                <p className="title">{this.state.productName}</p>
                <p className="subtitle">{this.state.description}</p>
                <div className="customer-icons">
                    { customerLogo }
                </div>
            </div>

        )
    }
}
