import React from 'react'
import './SectionSeparator.sass'
import {Row, Col} from 'react-bootstrap'

export default function SectionSeparator() {
    return (

        <Row className="line-wrapper">
            <Col className="line" lg={{ span: 10, offset: 1 }}>
                <div className="separator">
                    
                </div>
            </Col>
        </Row>
    )
}
