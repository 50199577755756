import React, { Component } from 'react'
import './ContactUsPage.sass'
import {Row, Col, Form} from 'react-bootstrap'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import Axios from 'axios'

export default class ContactUs extends Component {
    state= {
        header: {
            title: "Get In Touch With Us",
            desc: "A web-based platform where you can do all kinds of business activities from purchasing, marketing, production, inventory, to accounting. Your team can run your company from anywhere anytime.",
        },
        products: [],
        services: [],
        productId: null,
        serviceId: null,
        email: null,
        subject: null,
        message: null
    }

	getAllProducts = this.getAllProducts.bind(this)
    getAllServices = this.getAllServices.bind(this)

    handleInput = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleOptionChange = (e) => {
        if (e.target[e.target.selectedIndex].className == "opt-product") {
            this.setState({
                productId : e.target[e.target.selectedIndex].id
            })
        } else {
            this.setState({
                serviceId : e.target[e.target.selectedIndex].id
            })
        }
    }

    handleSubmit = (e) => {
        let data = {
            productId: this.state.productId,
            serviceId: this.state.serviceId,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }
        const dataJson = JSON.stringify(data)
        console.log(dataJson)
        e.preventDefault()
        
    }

    render() {
        var productOptions = this.state.products.map(product => {
            return(
                <option class="opt-product" id={product.id} key={product.id}>{product.name}</option>
            )
        })
        var serviceOptions = this.state.services.map(service => {
            return(
                <option class="opt-service" id={service.id} key={service.id} >{service.name}</option>
            )
        })
        return (
            <Row className="contact-page-wrapper">
                <Col className="contact-page">
                    <img src={Bg} id="bg" alt="" />
                    <BaseNavbar />
                    <Row className="contact-head-wrapper">
                        <Col className="contact-head" lg={{ span: 4, offset: 1 }} xs={{ span: 12 }}>
                            <Row>
                                <Col className="header" lg={{ span: 12 }}>
                                    <h1 className="title">{this.state.header.title}</h1>
                                    <p className="desc">{this.state.header.desc}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="form-wrapper" lg={{ span: 5, offset: 1 }}>
                            <Form className="form" onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>What can we help you?</Form.Label>
                                    <Form.Control as="select" required onChange={this.handleOptionChange}>
                                        <option id="general" selected>General</option>
                                        {productOptions}
                                        {serviceOptions}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control id="email" onChange={this.handleInput} required type="email"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control onChange={this.handleInput} required id="subject" type="text"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Tell us what happen</Form.Label>
                                    <Form.Control onChange={this.handleInput} required id="message" as="textarea" rows="7" />
                                </Form.Group>
                                <button type="submit" className="submit">Send</button>
                            </Form>
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
	
	getAllProducts() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		Axios({
			url: apiUrl + "products",
			method: "get"
		}).then(response => {
			this.setState({
				products: response.data
            })
		}).catch(err => {
            window.location.href = "/error/" + 500
		})
	}

	getAllServices() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		Axios({
			url: apiUrl + "services",
			method: "get"
		}).then(response => {
			this.setState({
				services: response.data
			})
			
		}).catch(err => {
            window.location.href = "/error/" + 500
		})
	}
	
	componentDidMount() {
        this.getAllProducts()
        this.getAllServices()
	}
}
