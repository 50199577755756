import React from 'react'
import './PageHeader.sass'
import { Row, Col } from 'react-bootstrap'
import MainButton from "../MainButton/MainButton"

export default function PageHeader(props) {
    var title = props.title
    var desc = props.desc
    var buttonText = props.buttonText
    var href = props.href

    if (buttonText === null) {
        return (
        <Row>
            <Col className="header" lg={{ span: 12 }}>
                <h1 className="title">{title}</h1>
                <p className="desc">{desc}</p>
            </Col>
        </Row>
        )
    }
    return (
        <Row>
            <Col className="header" lg={{ span: 12 }}>
                <h1 className="title">{title}</h1>
                <p className="desc">{desc}</p>
                <MainButton href={href} text={buttonText} />
            </Col>
        </Row>
    )
}
