import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './TestimoniCarousel.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight, faCircle, faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';

export default class TestimoniCarousel extends Component {
	state = {
		testimonies: []
	}

	getTestimony = this.getTestimony.bind(this)

	render () {
		// var slides = this.testimonies.map(slide => {
		// 	return (
		// 		<div className="slide-wrapper" key={slide.id}>
		// 			<div className="slide">
		// 				<div className="profile">
		// 					<img className="testi-pic" src={slide.image} alt="Credit to Joshua Earle on Unsplash"/>
		// 					<p className="name">{slide.name}, {slide.position}</p>
		// 					<p className="company">{slide.company}</p>
		// 				</div>
		// 				<div className="testimoni">
		// 					<p><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></p>
		// 					<p className="text">{slide.description}</p>
		// 					<p><FontAwesomeIcon className="float-right icon" icon={faQuoteRight} /></p>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	)
		// })
		var settings = {
			customPaging: function(i) {
				return (
					<a>
						<FontAwesomeIcon icon={ faCircle } />
					</a>
				);
			},
			dots: true,
			dotsClass: "slick-dots slick-thumb",
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			nextArrow: <FontAwesomeIcon className="navigation-icon" icon={faChevronCircleRight} 
							style= "font-size: 1.5rem; color: #ffffff"/>,
			prevArrow: <FontAwesomeIcon className="navigation-icon" icon={faChevronCircleLeft} />
		};
		return (
			<div className="carousel">
				<Slider {...settings} className="slides d-flex text-white">
					{/* {slides} */}
					<div className="slide-wrapper">
			 			<div className="slide">
			 				<div className="profile">
			 					<img className="testi-pic" src="https://img.okezone.com/content/2020/06/02/611/2223428/5-potret-warna-warni-rambut-dahyun-twice-cute-banget-ZAjBPnw4GT.jpg" alt="Credit to Joshua Earle on Unsplash"/>
			 					<p className="name">Igor Lestin, UI Designer</p>
			 					<p className="company">KOTA Digivice</p>
			 				</div>
			 				<div className="testimoni">
			 					<p><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></p>
			 					<p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor elementum dapibus. </p>
			 					<p><FontAwesomeIcon className="float-right icon" icon={faQuoteRight} /></p>
			 				</div>
			 			</div>
			 		</div>
					<div className="slide-wrapper">
			 			<div className="slide">
			 				<div className="profile">
			 					<img className="testi-pic" src="https://img.okezone.com/content/2020/06/02/611/2223428/5-potret-warna-warni-rambut-dahyun-twice-cute-banget-ZAjBPnw4GT.jpg" alt="Credit to Joshua Earle on Unsplash"/>
			 					<p className="name">Igor Lestin, UI Designer</p>
			 					<p className="company">KOTA Digivice</p>
			 				</div>
			 				<div className="testimoni">
			 					<p><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></p>
			 					<p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor elementum dapibus. </p>
			 					<p><FontAwesomeIcon className="float-right icon" icon={faQuoteRight} /></p>
			 				</div>
			 			</div>
			 		</div>
					<div className="slide-wrapper">
			 			<div className="slide">
			 				<div className="profile">
			 					<img className="testi-pic" src="https://img.okezone.com/content/2020/06/02/611/2223428/5-potret-warna-warni-rambut-dahyun-twice-cute-banget-ZAjBPnw4GT.jpg" alt="Credit to Joshua Earle on Unsplash"/>
			 					<p className="name">Igor Lestin, UI Designer</p>
			 					<p className="company">KOTA Digivice</p>
			 				</div>
			 				<div className="testimoni">
			 					<p><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></p>
			 					<p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor elementum dapibus. </p>
			 					<p><FontAwesomeIcon className="float-right icon" icon={faQuoteRight} /></p>
			 				</div>
			 			</div>
			 		</div>
				</Slider>
			</div>
		)
	}

	getTestimony() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		Axios({
			url: apiUrl + "testimony",
			method: "get"
		})
		.then(response => {
			this.setState({
				testimonies: response.data
			})
			console.log(this.state.testimonies)
		})
		.catch(err => {
			console.log(err)
		})
	}

	componentDidMount() {
		this.getTestimony()
	}
}
