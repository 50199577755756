import React, { Component } from 'react'
import './ArticlePage.sass'
import { Row, Col } from 'react-bootstrap'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import Bg from '../../bg/homepage.jpg'
import Axios from 'axios'
import MoreArticle from '../../Component/MoreArticle/MoreArticle'

export default class ArticlePage extends Component {
    state= {
        articleId: null,
        article: {},
        moreArticle: []
    }

    getArticle = this.getArticle.bind(this)

    render() {
        const { match: { params } } = this.props
        return (
            <Row className="article-page-wrapper">
                <Col className="article-page">
                    <BaseNavbar />
                    <Row className="article-wrapper">
                        <Col className="article" lg={{ span: 10, offset: 1 }}>
                            <p className="title">{this.state.article.title}</p>
                            <p className="subtitle">{this.state.article.title}</p>
                            <div className="image-wrapper">
                                <img src={Bg} alt="article" className="image"/>
                                <p className="source">asdasdasdasdasdasdas adsd as asd asd as</p>
                            </div>
                            <p>{this.state.article.content}</p>
                        </Col>
                    </Row>
                    <Row className="more-section-wrapper">
                        <Col className="more-section" lg={{ span: 10, offset: 1 }}>
                            <p className="more">More Article</p>
                            <MoreArticle renderedId={params.id_article} />
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
    getArticle(id) {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "case-studies/" + id,
            method: "get"
        }).then(response => {
            this.setState({
                article: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({
            articleId: params.id_article
        })
        this.getArticle(params.id_article)
    }
}
