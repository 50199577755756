import React, { Component } from 'react'
import './ProductPage.sass'
import { Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import ReactPlayer from 'react-player'
import Banner from '../../bg/carousel-bg.jpg'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import PageHeader from '../../Component/PageHeader/PageHeader'
import FeatureSection from '../../Component/Product/FatureSection/FeatureSection'
import MainButton from '../../Component/MainButton/MainButton'
import SectionSeparator from '../../Component/SectionSeparator/SectionSeparator'
import FaqSection from '../../Component/Product/FaqSection/FaqSection'
import Plan from '../../Component/Product/Plan/Plan';

export default class ProductPage extends Component {
    state= {
        header: {
            title: null,
            desc: null,
            buttonText: null,
            href: null
        },
        productId: null,
        videoProduct: null,
    }
    getProduct = this.getProduct.bind(this)

    render() {
        const { match: { params } } = this.props;
        return (
            <Row className="product-page-wrapper">
                <Col className="product-page">
                    <img src={Bg} id="bg" alt="" />
                    <BaseNavbar />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <PageHeader 
                                title={this.state.header.title}
                                desc={this.state.header.desc}
                                buttonText={this.state.header.buttonText}
                                href={this.state.header.href}
                                />
                        </Col>
                    </Row>
                    <Row className="feature-section-wrapper">
                        <Col className="feature-section base-padding" lg={{ span: 10, offset: 1 }}>
                            <FeatureSection productId={params.id_product} />
                        </Col>
                    </Row>
                    {/* <SectionSeparator />
                    <Row className="video-section-wrapper">
                        <Col className="video-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">Video Tutorial</p>
                            <p className="section-subtitle">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                            <div className="video-player">
                                <ReactPlayer className="video-player" 
                                    width="100%"
                                    height="100%"
                                    // playing= {true}
                                    controls= {true}
                                    playsinline
                                    url={this.state.videoProduct}
                                    config={{ file: { 
                                        attributes: {
                                        controlsList: 'nodownload'
                                        }
                                    }}}
                                    />
                            </div>
                        </Col>
                    </Row> */}
                    <Row className="banner-section-wrapper">
                        <Col className="banner-section">
                            <img src={Banner} alt="" className="bg"/>
                            <div className="blur"></div>
                            <div className="banner">
                                <p className="banner-title">How can we help you?</p>
                                <MainButton href="/contact" text="Let me know your needs" size="lg" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="pricing-section-wrapper">
                        <Col className="pricing-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">Pricing</p>
                            <p className="section-subtitle">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                            <div className="plans">
                                <Plan productId={params.id_product} />
                            </div>
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="faq-section-wrapper">
                        <Col className="faq-section" lg={{ span: 10, offset: 1 }}>
                            <FaqSection productId={params.id_product} />
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }

    getProduct(productId) {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "products/" + productId,
            method: "get"
        })
        .then(response => {
            this.setState({
                header: {
                    title: response.data.name,
                    desc: response.data.description,
                    buttonText: "Get free trial",
                    href: "/contact"
                },
                videoProduct: response.data.video
            })
        })
        .catch(err => {
            window.location.href = "/404/"
        })
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.getProduct(params.id_product)
    }
}
