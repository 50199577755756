import React, { Component } from 'react'
import './MainModal.sass'
import { Modal } from 'react-bootstrap'
import Axios from 'axios'

export default class MainModal extends Component {
	constructor(props) {
		super(props);
		// Don't call this.setState() here!
		this.state = { 
			data: []
		}
		this.clearState()
  	}
	
	clearState() {
		this.setState({
			data: []
		})
	}
	getAllProducts = this.getAllProducts.bind(this)
	getAllServices = this.getAllServices.bind(this)
	
    render() {
		// var dataType = this.props.modalType === "service" ? (this.state.data.services) : (this.state.data.product)
		var data = this.state.data.map(object => {
			var desc = ""
			if(object.desc_brief.length <= 50) {
				desc = object.desc_brief
			} else {
				desc = object.desc_brief.slice(0, 50) + "..."
			}

			return (
				<a href={"/" + this.props.modalType + "s/" + object.id} key={object.id}>
					<div className="product" key={object.id}>
						<div className="info">
							<p className="name">{object.name}</p>
							<p className="desc">{desc}</p>
						</div>
					</div>
				</a>
			)
		})
		
        return (
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="product-modal"
            >
                <Modal.Body>
					{data}
                </Modal.Body>
            </Modal>
        )
	}
	
	getAllProducts() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		Axios({
			url: apiUrl + "products",
			method: "get"
		}).then(response => {
			this.setState({
				data: response.data
			})
		}).catch(err => {
            window.location.href = "/error/" + 500
		})
	}

	getAllServices() {
        let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		Axios({
			url: apiUrl + "services",
			method: "get"
		}).then(response => {
			this.setState({
				data: response.data
			})
			
		}).catch(err => {
            window.location.href = "/error/" + 500
		})
	}
	
	componentDidMount() {
		if (this.props.modalType === "product") {
			this.getAllProducts()
		} else {
			this.getAllServices()
		}
	}
}